var OutputApp = function(rdata) {
    var self = this,
        currentState = 'healthcare',

        $populationControl;

    /**
     * Render sections based on outputData
     * @param {jQuery} $target
     * @param {Object} data
     */
    function renderSections($target, data) {
        $.each(data, function(k, data) {
            renderSection($target, k, data);
        });
    }

    /**
     * Render a single section
     * @param {jQuery} $target
     * @param {String} name
     * @param {Object} data
     */
    function renderSection($target, name, data) {
        var $panel = createAccordionPanel($target, name);

        if(data.graphs) renderGraphs($panel, data.graphs);
        if(data.controls) renderControls($panel, data.controls, data.graphs);
        if(data.tables) renderTables($panel, data.tables);

        var $buttons = $panel.find('.button-toggle');

        $buttons.each(function() {
            var $e = $(this),
                $targets = $buttons.not($e);

            $e.trigger('togglebutton.sync', $targets);
        });
    }

    /**
     * Render section graphs
     * @param {jQuery} $target
     * @param {Object} data
     */
    function renderGraphs($target, data) {
        $.each(data, function(k, data) {
            renderGraph($target, k, data);
        });

        // Force population multiplication
        $populationControl.find('input').trigger('change');
    }

    /**
     * Render a single graph
     * @param {jQuery} $target
     * @param {String} name
     * @param {Object} data
     */
    function renderGraph($target, name, data) {
        var $size = $target.closest('.accordion'),
            $graph = $('<div />')
                .attr(data.attributes || {})
                .addClass('graph'),

            options = getChartOptions($size, name, data);

        $target.append($graph);

        $graph.highcharts(options);
        data.graph = $graph.highcharts();
        data.graph._originalTitle = name;
    }

    /**
     * Render section controls
     * @param {jQuery} $target
     * @param {Array} controls
     * @param {Object} data
     */
    function renderControls($target, controls, data) {
        $.each(controls, function(i, options) {
            var func = 'render' + options.type.toUpperCaseFirst() + 'Controls';

            // Map controls type to a function name
            if(typeof self[func] === 'function') {
                self[func]($target, options, data);
            }
        });
    }

    /**
     * Render ICER controls
     * @param {jQuery} $target
     * @param {Object} options
     * @param {Object} data
     */
    function renderIcerControls($target, options, data) {
        var target = data[options.target],
            $controls = $('<div>').addClass('form-inline control--threshold'),
            $title = $('<div>').addClass('form-group')
                .append($('<strong>').text(options.name))
                .append('&nbsp;'),

            name = getControlsName();

        $target.append($controls.append($title));

        if(!target) return;

        // Create input for every option
        $.each(options.options, function(label, value) {
            var $formgroup = $('<div>').addClass('form-group'),
                $label = $('<label>'),
                $radio = $('<input>').attr({
                    type: 'radio',
                    name: name,
                    value: value
                });

            $controls.append($formgroup.append($label.append($radio).append(label)));
        });

        $controls.find('input').eq(0).prop('checked', true);

        // Initialize input interaction
        $controls.find('input[name="' + name + '"]').on('change', function() {
            var threshold = parseInt($(this).val()),
                series = target.series[currentState] || target.series,
                seriesIndex = -1;

            $.each(series, function(i, serie) {
                if(serie.name !== 'WTP Threshold') return;
                seriesIndex = i;
            });

            target.graph.series[seriesIndex].setData([
                [-THRESHOLD_MULTIPLIER, threshold * -THRESHOLD_MULTIPLIER],
                [THRESHOLD_MULTIPLIER, threshold * THRESHOLD_MULTIPLIER]
            ]);
        });
    }

    // Expose function to allow access from renderControls()
    this.renderIcerControls = renderIcerControls;

    /**
     * Render section tables
     * @param {jQuery} $target
     * @param {Object} data
     */
    function renderTables($target, data) {
        var $tabList = $('<ul>').addClass('nav nav-tabs').attr('role', 'tablist'),
            $tabPanels = $('<div>').addClass('tab-content'),
            isFirst = true,
            shouldRenderTabs = !$.isArray(data);

        if(shouldRenderTabs) $target.append($tabList).append($tabPanels);

        $.each(data, function(name, data) {
            if (data.visible === undefined) data.visible = true;
            if (!data.visible) return;

            var id = name.toString().toLowerCase(),
                $tab = $('<li>')
                    .attr('role', 'presentation'),

                $tabLink = $('<a>')
                    .attr('href', '#' + id)
                    .attr('role', 'tab')
                    .data('toggle', 'tab')
                    .text(name),

                $panel = $('<div>').addClass('tab-pane').attr({
                    id: id,
                    role: 'tabpanel'
                });

            if(isFirst) {
                $tab.addClass('active');
                $panel.addClass('active');
                isFirst = false;
            }

            if(shouldRenderTabs) {
                $tabList.append($tab.append($tabLink));
                $tabPanels.append($panel);

                if(!data.flipped) renderTable($panel, $tab, data);
                else renderFlippedTable($panel, data);
            } else {
                if(!data.flipped) renderTable($target, $tab, data);
                else renderFlippedTable($target, data);
            }
        });
    }

    /**
     * Render a single table
     * @param {jQuery} $target
     * @param {Object} data
     */
    function renderTable($target, $tab, data) {
        var $table = $('<table />')
                .attr(data.attributes || {})
                .addClass('table table-striped table--data'),

            $thead = $('<thead />'),
            $tbody = $('<tbody />'),
            $row = $('<tr />'),

            buttonLabels = {
                closed: 'Show full table',
                open: 'Hide full table'
            },

            $button = $('<button>').addClass('btn btn-info btn-sm').text(buttonLabels.closed),

            totalRows = Math.ceil(data.data.length / data.columns.length),
            visibleRows = 4;

        // Add responsive classes to tab panel
        $.each($table.attr('class').split(/\s/g), function(i, className) {
            if(className.indexOf('hidden-') < 0) return;
            $target.addClass(className);
            $tab.addClass(className);
        });

        $target.append($table.append($thead.append($row)).append($tbody));

        if(data.data.length > visibleRows) {
            $target.append($button);
        }

        // Populate $thead
        $.each(data.columns, function(i, column) {
            var columnName = $.isPlainObject(column) ? column.name : column,
                $cell = $('<th>')
                    .attr(column.attributes)
                    .addClass(getHeadColumnClass(column));

            $row.append($cell.text(columnName));
        });

        // Populate $tbody
        $.each(data.data, function(i, val) {
            if(typeof val !== 'number' && !isNaN(val)) val = Number.parseFloat(val);

            var columnIndex = i % data.columns.length,
                column = data.columns[columnIndex],
                columnName = $.isPlainObject(column) ? column.name : column,
                columnClass = getColumnTypeByText(columnName),

                row = Math.floor(i / data.columns.length),
                rowVisible = row < totalRows - visibleRows,

                $cell = $('<td>');

            if(columnIndex === 0) {
                $row = $('<tr>').toggleClass('row--preview', rowVisible);
                $tbody.append($row);
            }

            if(typeof val === 'number' && column.decimals) {
                val = val.toFixed(column.decimals);
            }

            if(columnClass) $cell.addClass(columnClass);
            $row.append($cell.text(val));
        });

        var tableVisible = false;

        $tbody.find('tr').hide().not('.row--preview').show();

        $button.on('click', function() {
            if(!tableVisible) {
                $tbody.find('tr').show();
                $button.text(buttonLabels.open);
            } else {
                $tbody.find('tr').hide().not('.row--preview').show();
                $button.text(buttonLabels.closed);
            }

            tableVisible = !tableVisible;
        });
    }

    /**
     * Render a flipped (transposed) table
     * @param $target
     * @param data
     */
    function renderFlippedTable($target, data) {
        var $table = $('<table />')
                .attr(data.attributes || {})
                .addClass('table table--data'),

            $thead = $('<thead>'),
            $tbody = $('<tbody />');

        $target.append($table.append($thead).append($tbody));

        // Populate $tbody
        $.each(data.data, function(i, val) {
            if(typeof val !== 'number' && val.length && !isNaN(val)) val = Number.parseFloat(val);

            var propertyIndex = i % data.columns.length,
                property = data.columns[propertyIndex],
                propertyName = property.name || property,

                $row = $('<tr>')
                    .addClass(getHeadColumnClass(property, true)),

                $name = $('<th>')
                    .attr(property.attributes || {})
                    .text(propertyName),

                $cell = $(i < 1 ? '<th>' : '<td>'),
                $target = $(i < 1 ? $thead : $tbody),
                $control = undefined;

            if(typeof val === 'number' && property.decimals) {
                val = val.toFixed(property.decimals);
            } else if(typeof val === 'string') {
                val = val.replace('{population-control}', function() {
                    $control = $('<span id="population-control">');
                    return '';
                });
            }

            $target.append($row.append($name).append($cell.text(val)));
            if($control) $cell.append($control);
        });
    }

    /**
     * Get column type by text
     * @param {String} text
     * @returns {String|undefined}
     */
    function getColumnTypeByText(text) {
        text = text.toString();

        if(text.match(/societal/i)) return 'col-societal';
        if(text.match(/health(\s+)?care/i)) return 'col-healthcare';
        return undefined;
    }

    /**
     * Get head column class
     * @param {String|Object} column
     * @returns {String}
     */
    function getHeadColumnClass(column, ignoreAttributes) {
        if(typeof column === 'string') return getColumnTypeByText(column);
        if(!column.attributes) column.attributes = {};

        var classes = [getColumnTypeByText(column.name)];
        if(!ignoreAttributes) classes.concat(column.attributes.class);

        return classes.join(' ');
    }

    /**
     * Toggle graph state
     * @param {String} state
     * @param {Object} data
     */
    function toggleGraphState(state, data) {
        $.each(data, function(section, data) {
            if(!data.graphs) return;

            var isPopulationGraph = (section === 'Population impact'),
                population = getPopulationCount();

            $.each(data.graphs, function(name, data) {
                if(!data.series || !data.series[state]) return;

                // Update graph title
                data.graph.setTitle({
                    text: getGraphStateTitle(data.graph._originalTitle, state)
                });

                // Change graph series
                $.each(data.graph.series, function(i, serie) {
                    if(!isPopulationGraph) {
                        serie.setData(data.series[state][i].data);
                    } else {
                        serie.setData(multiplyPopulationImpactData(data.series[state][i].data, population));
                    }
                });
            });
        });
    }

    /**
     * @param {jQuery} $size
     * @param {String} name
     * @param {Object} data
     * @returns {Object}
     */
    function getChartOptions($size, name, data) {
        var size = $size.width(),
            defaults = {
                colors: colors,
                credits: false,

                chart: {
                    height: size,
                    width: size,
                    legend: {
                        align: 'center',
                        borderWidth: 0,
                        layout: 'horizontal',
                        verticalAlign: 'bottom'
                    }
                },

                plotOptions: {
                    line: {
                        marker: {
                            enabled: false,
                            symbol: 'circle'
                        },
                        tooltip: {
                            pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <strong>{point.y:,.3f}</strong>'
                        }
                    },
                    scatter: {
                        marker: { radius: 5 },
                        tooltip: {
                            pointFormat: 'x: <strong>{point.x:,.3f}</strong><br />y: <strong>{point.y:,.3f}</strong>'
                        }
                    }
                },

                xAxis: {
                    tickmarkPlacement: 'on'
                }
            },

            options = $.extend(true, {}, defaults, $.cloneObject(data));

        var stateSeries = options.series[currentState],
            title = stateSeries ? getGraphStateTitle(name, currentState) : name;

        options.title = {text: title};
        options.series = stateSeries || options.series;

        return options;
    }

    /**
     * Get population control
     * @returns {jQuery}
     */
    function getPopulationControl(outcome, population) {
        var $group = $('<div>').addClass('form-group control control--population'),
            $label = $('<label>').append(
                $('<span>').text('Population:')
            ),
            $input = $('<input>')
                .attr({type: 'number'})
                .addClass('form-control form-control-inline input-sm')
                .val(population),
            $reset = $('<button>')
                .addClass('btn-default btn-sm btn-success')
                .text('Reset'),
            timeout;

        $group.append($label.append($input).append($reset));

        $reset.on('click', function() {
            $input.val(population).trigger('change');
        });

        $input.on('keyup change', function() {
            var population = getPopulationCount(),
                data = $.map(outcome, function(value) {
                    return value * population;
                });

            if(timeout) clearTimeout(timeout);

            // Prevent being fired several times
            timeout = setTimeout(function() {
                $('#population-impact tr td:nth-child(2)').map(function(i, e) {
                    var $e = $(e);
                    $e.text(Number.parseFloat(data[i]).toFixed(2));
                });

                $.each(rdata['Population impact'].graphs, function(name, graph) {
                    $.each(graph.graph.series, function(i, serie) {
                        var series = graph.series[currentState] || graph.series;
                        serie.setData(multiplyPopulationImpactData(series[i].data, population));
                    });
                });
            }, 1000 / 60);
        });

        return $group;
    }

    function multiplyPopulationImpactData(data, multiplier) {
        return $.map(data, function(data) {
            return data * multiplier;
        });
    }

    function getPopulationCount() {
        return $populationControl.find('input').val();
    }

    /****************************************************************
     * Initialisation
     ****************************************************************/

    var $target = $('#output'),
        $accordion = createAccordion($target),

        populationImpactData = rdata['Cost-effectiveness'].data;

    // Initialize PopulationControl before rendering - we need $populationControl when rendering charts
    $populationControl = getPopulationControl(populationImpactData.outcome, populationImpactData.population);

    // Populate accordion
    renderSections($accordion, rdata);
    toggleTableState(currentState);

    // Create accordion
    $accordion.accordion({
        heightStyle: 'content'
    });

    // Add togglebutton
    $.toggleButton({
        label: {
            off: 'Healthcare costs',
            on: 'Societal costs'
        }
    }).on('togglebutton.on', function() {
        if(currentState === 'societal') return;

        currentState = 'societal';
        toggleGraphState(currentState, rdata);
        toggleTableState(currentState);
    }).on('togglebutton.off', function() {
        if(currentState === 'healthcare') return;

        currentState = 'healthcare';
        toggleGraphState(currentState, rdata);
        toggleTableState(currentState);
    }).insertBefore($accordion);

    var $populationTable = $('#population-impact'),
        $populationControlWrapper = $('#population-control');

    $populationControlWrapper.append($populationControl);

    // Move $populationTable above charts
    $populationTable.prependTo($populationTable.closest('.accordion__panel'));

    // Initialize tabs
    $('.nav-tabs a').click(function(e) {
        e.preventDefault();
        $(this).tab('show');
    });

    /****************************************************************
     * Text injection
     ****************************************************************/

    $('<p>').text('Incremental cost and health-related quality of life are shown here for every age-gender combination in the specified target population. Both the discounted and the undiscounted incremental outcomes are presented. Moving the cursor over the curves will display the incremental outcomes for each age-category. This information is also provided in the expandable table below.').insertBefore($('#incremental-resource-cost'));

    $('<p>').text('This section presents the overall impact of the intervention on healthcare/societal cost and quality-adjusted life years (QALYs) for the total target population. Results represent the average per-patient outcome across all age-gender combinations (weighted by the age-gender distribution in the specified target country and age range).')
        .add($('<p>').text('Results are summarised in the table below and also presented in a cost-effectiveness plane. This plane plots the incremental cost of the intervention on the y-axis and the incremental health outcome (measured in QALYs) on the x-axis. The diagonal line represents the willingness to pay (WTP) per additional QALY gained. It is also referred to as the threshold value (λ).'))
        .add($('<p>').html('The table shows the population-level impact on incremental cost and health-related quality of life, as well as the incremental cost-effectiveness ratio (ICER). For both dominant interventions (i.e. more effective <em>and</em> less costly) and dominated interventions (i.e. less effective <em>and</em> more costly) this is shown instead of the ICER.'))
        .insertBefore($('#cost-effective-plane'));

    $('<p>').text('The charts below represent incremental costs and incremental effects (QALYs) accumulated over the model time horizon. You can set the population size according to the size of your target cohort in the field below.')
        .add($('<p>').text('Hovering over the curves will display cumulative impact estimates for the entire target population in a given year. Note however that this figure relates to one cohort followed over time only and does not take into account the fact that every year additional people may join the target cohort.'))
        .add($('<p>').text('If the population size entered here differs from the population size assumed to calculate the share of annual fixed cost per patient, you can go back to the data entry sheet and adjust the total cost per person per year accordingly in order to account for economies of scale.'))
        .insertBefore($('#population-impact'));

    $('<p>').text('The flow of the selected patient through the different states of the model is shown in the two graphs below. The x-axis shows the age of the patient from the selected age untill 100. The y-axis reflects the probability of staying in a given health state at each age.').insertBefore($('#flow-evaluation'));

    /****************************************************************
     * Restructuring
     ****************************************************************/

    $('#cost-effectiveness-table').insertBefore($('#cost-effective-plane'));
};