!(function($) {
    var COOKIE_NAME = 'cookie-accepted',
        COOKIE_OPTIONS = { expires: 365 };

    $.fn.cookieNotification = function() {
        this.each(function() {
            var $element = $(this);

            // Do nothing if cookies are already accepted
            if (Cookies.get(COOKIE_NAME)) return;

            // Show cookie notification
            $element.show();

            // Initialize the accept button
            $element.find('#cookie-btn-accept').on('click', function() {
                Cookies.set('cookie-accepted', true, COOKIE_OPTIONS);
                $element.hide();
            });

            // Intialize refuse button
            $element.find('#cookie-btn-refuse').on('click', function() {
                window.location = 'http://ec.europa.eu/';
            });
        });
    };
})(jQuery);