(function(global, $) {
    var controlsIndex = -1;

    /**
     * Create accordion
     * @param {jQuery} $target
     * @returns {jQuery}
     */
    global.createAccordion = function($target) {
        var $accordion = $('<div>');

        $target.append($accordion);
        return $accordion;
    };

    /**
     * Creates an accordion panel
     * @param {jQuery} $accordion
     * @param {String} title
     * @returns {jQuery}
     */
    global.createAccordionPanel = function($accordion, title) {
        var $header = $('<h2 />').addClass('accordion__header').text(title),
            $panel = $('<div />').addClass('accordion__panel');

        $accordion.append($header).append($panel);
        return $panel;
    };

    /**
     * Sort series of data, because Highcharts wont
     * @param {Array<Object>} series
     */
    global.sortSeries = function(series) {
        $.each(series, function(i, serie) {
            serie.data = getSortedSerieData(serie.data);
        });
    };

    /**
     * Toggle table state
     * @param {String} state
     */
    global.toggleTableState = function(state) {
        var $healthcare = $('.col-healthcare'),
            $societal = $('.col-societal');

        if(state === 'healthcare') {
            $healthcare.show();
            $societal.hide();
        } else if(state === 'societal') {
            $healthcare.hide();
            $societal.show();
        }
    };

    /**
     * Sort a single serie
     * @param data
     * @returns {Array}
     */
    global.getSortedSerieData = function(data) {
        return data.sort(function(a, b) {
            var ax = $.isArray(a) ? a[0] : a.x,
                bx = $.isArray(b) ? b[0] : b.x;

            return ax - bx;
        });
    };

    /**
     * @returns {String}
     */
    global.getControlsName = function() {
        var index = ++controlsIndex;
        return 'controls-' + index;
    };

    /**
     * @param {String} title
     * @param {String} state
     * @returns {String}
     */
    global.getGraphStateTitle = function(title, state) {
        var stateLabel = getStateLabel(state);
        return title + ' (' + stateLabel + ')';
    };

    /**
     * @param {String} state
     * @returns {String}
     */
    global.getStateLabel = function(state) {
        return state.toUpperCaseFirst();
    };
})(window, jQuery);