(function($) {
    var uid = -1,
        eventNS = 'togglebutton.',
        eventNSPrivate = 'togglebutton._';

    /**
     * Creates a toggle button
     * @param {Object} options
     * @returns {jQuery}
     */
    $.toggleButton = function(options) {
        var id = options.id || 'button-toggle-' + (++uid),

            $btn = $('<div>').addClass('button-toggle'),
            $checkbox = $('<input type="checkbox">').attr('id', id),

            // Labels
            $label_button = $('<label>')
                .addClass('button-toggle__button').attr('for', id),

            $label_on = $('<label>')
                .addClass('button-toggle__label button-toggle__label--on')
                .text(options.label.on).attr('for', id),

            $label_off = $('<label>')
                .addClass('button-toggle__label button-toggle__label--off')
                .text(options.label.off).attr('for', id);

        $btn.append($checkbox, $label_off, $label_button, $label_on);

        $checkbox.on('change', function() {
            var checked = $checkbox.is(':checked'),
                event = checked ? 'on' : 'off';

            $btn.trigger(eventNSPrivate + event)
                .trigger(eventNS + event);
        });

        $(window).on('load', function() {
            $checkbox.trigger('change');
        });

        /*
         * "Methods" through events
         * Example: $e.trigger('togglebutton.sync', $targets)
         */
        $btn.on(eventNS + 'sync', function(e, targets) {
            $(targets).on(eventNSPrivate + 'on', function() {
                $checkbox.not(':checked')
                    .prop('checked', true)
                    .trigger('change');
            }).on(eventNSPrivate + 'off', function() {
                $checkbox.filter(':checked')
                    .prop('checked', false)
                    .trigger('change');
            });
        });

        return $btn;
    };
})(jQuery);