// Multiplier for WTP Threshold controls, to make the line appear infinite
// Highcharts breaks at anything larger than 1 (apparently)
var THRESHOLD_MULTIPLIER = 1;

// Colour palette for Policies
// http://ec.europa.eu/dgs/communication/services/visual_identity/pdf/charter-by-chapter/visual-colours_en.pdf
var colors = [
    '#3f85c1', // 49
    '#2aad89', // 50
    '#76b861', // 51
    '#ee8032', // 51
    '#e42032'  // 28
];