/**
 * Initialize Overview logic
 */
var OverviewApp = function() {
    $('.delete-analysis').on('click', function(event) {
        if(confirm('Are you sure?')) {
            var deleteLink = $(this),
                deleteUrl = deleteLink.attr('href'),
                analysisId = deleteLink.data('analysis-id');

            deleteLink.addClass('disabled');

            $.post(deleteUrl, {analysisId: analysisId}, function(data) {
                if (data.success) {
                    window.location = window.location;
                } else {
                    alert('Something went wrong.');
                    deleteLink.removeClass('disabled');
                }
            });
        }

        event.preventDefault();
        return false;
    });
};
