/**
 * Initialize Form
 * @param {jQuery} $sensitivityTable
 */
var FormApp = function($sensitivityTable) {
    var $accordion = $('.accordion'),
        $form = $accordion.closest('form'),
        $errorFields = $accordion.find('.form-group.error'),
        errorPanelIndex;

    $accordion.accordion({
        heightStyle: 'content'
    });

    if ($errorFields.length) {
        errorPanelIndex = $errorFields.eq(0)
            .closest('.accordion__panel')
            .index('.accordion__panel');

        $accordion.accordion('option', 'active', errorPanelIndex);
    }

    $('[data-toggle="tooltip"]').tooltip();

    // If the form is disabled, disable the disable toggle functionality
    if(!$form.data('disabled') && $sensitivityTable && $sensitivityTable.length) {

        // Disable submit button if no checkboxes are enabled (default for page load)
        if ($sensitivityTable.find('tbody tr td:first-child input').filter(':checked').length == 0) {
            $('button').prop('disabled', true);
        }

        /**
         * Disable the non-checked input boxes when the maximum (10) is reached
         * @param {jQuery} $checkboxes
         */
        var checkMaxCheckboxes = function($checkboxes) {

            // Disable submit button if no checkboxes are enabled
            if ($checkboxes.filter(':checked').length == 0) {
                $('button').prop('disabled', true);
            } else {
                $('button').prop('disabled', false);
            }

            // Disabled checkboxes if more than 10 are checked
            if ($checkboxes.filter(':checked').length == 10) {
                $checkboxes.filter(':not(:checked)').prop('disabled', true);
            } else {
                $checkboxes.prop('disabled', false);
            }
        };

        // Initialize sensitivityTable interaction
        $sensitivityTable.find('tbody tr').each(function(i, e) {
            var $e = $(e),
                $input_enabled = $e.find('td:first-child input'),
                $input_min = $e.find('.sensitivity-min'),
                $input_max = $e.find('.sensitivity-max'),
                $tooltips = $e.find('[data-toggle="tooltip"]'),
                enabled = $input_enabled.is(':checked');

            $input_min.prop('disabled', !enabled);
            $input_max.prop('disabled', !enabled);
            $tooltips.tooltip(enabled ? 'disable' : 'enable');

            $input_enabled.on('change', function() {
                var enabled = $input_enabled.is(':checked');
                $input_min.prop('disabled', !enabled);
                $input_max.prop('disabled', !enabled);
                $tooltips.tooltip(enabled ? 'disable' : 'enable');

                checkMaxCheckboxes($sensitivityTable.find('tbody tr td:first-child input'));
            });
        });
    }

    // Add check for dependent gender fields in model input
    var genderInput = $('[name="gender"]');
    var selectedGenderMaleInput = $('[name="selected-gender"][value="0"]');
    var selectedGenderFemaleInput = $('[name="selected-gender"][value="1"]');

    if (genderInput && selectedGenderMaleInput && selectedGenderFemaleInput) {
        var checkGenderDependency = function() {
            var gender = genderInput.val();

            // Default: enable both fields
            selectedGenderMaleInput.prop('disabled', false);
            selectedGenderMaleInput.closest('label').removeClass('disabled');
            selectedGenderFemaleInput.prop('disabled', false);
            selectedGenderFemaleInput.closest('label').removeClass('disabled');

            switch (gender) {
                // Male
                case '2':
                    selectedGenderMaleInput.prop('checked', true);
                    selectedGenderFemaleInput.prop('disabled', true);
                    selectedGenderFemaleInput.closest('label').addClass('disabled');
                    break;
                // Female
                case '3':
                    selectedGenderFemaleInput.prop('checked', true);
                    selectedGenderMaleInput.prop('disabled', true);
                    selectedGenderMaleInput.closest('label').addClass('disabled');
                    break;
            }
        };

        genderInput.on('change', function() {
            checkGenderDependency();
        });
        checkGenderDependency();
    }

    // Initialize modal trigger
    $('[data-trigger-modal]').each(function() {
        var $e = $(this),
            $target = $('#' + $e.data('trigger-modal'));

        $e.on('click', function() {
            showModal($target);
        });
    });

    // Initialize nested form options (probabilistic form)
    $('[data-show-target]').each(function() {
        var $e = $(this),
            $target = $('[name="' + $e.data('showTarget') + '"]'),
            targetValue = $e.data('showValue');

        /**
         * Toggle nested rows
         * @param {String} value
         */
        var showHideTargets = function(value) {
            if (value == targetValue) {
                $e.show();
            } else {
                $e.hide();

                // Reset inputs when parent option is disabled
                $e.find('input[type="checkbox"],input[type="radio"]')
                    .prop('checked', false)
                    .triggerHandler('change');
            }
        };

        $target.on('change', function() {
            showHideTargets($target.filter(':checked').val());
        });
        showHideTargets($target.filter(':checked').val());
    });

    // Initialize input enter key
    (function() {
        var $inputs = $('input');

        $inputs.on('keydown', function(e) {
            if(e.keyCode !== 13) return;

            var currentIndex = $inputs.index($(this)),
                nextIndex = currentIndex + 1;

            if(nextIndex < $inputs.length) {
                e.preventDefault();
                e.stopPropagation();

                // Focus next element
                $inputs.eq(nextIndex).focus();
            }
        });
    })();

    // Initialize currency symbol replacement
    (function() {
        $('[name="currency"]').on('change', function() {
            var symbol = $(this).find('option:selected').eq(0).data('symbol');
            $('[data-currency]').text(symbol);
        });
    })();

    // Initialize leave popup
    (function() {
        var shouldShowAlert = false;

        // Show popup when any field changes
        $('input, select, textarea').on('change', function() {
            shouldShowAlert = true;
        });

        // Do not show popup when the button is submit
        $('button[type="submit"]').on('click', function() {
            shouldShowAlert = false;
        });

        var leaveWindow = window.attachEvent || window.addEventListener;
        var checkEvent = window.attachEvent ? 'onbeforeunload' : 'beforeunload'; /// make IE7, IE8 compitable

        leaveWindow(checkEvent, function(e) { // For >=IE7, Chrome, Firefox
            if (!shouldShowAlert) {
                return;
            }
            var confirmationMessage = 'You have attempted to leave this page. Your changes will be lost.';
            (e || window.event).returnValue = confirmationMessage;
            return confirmationMessage;
        });
    })();

    // Initialize pre-analysis status check
    (function() {
        var isValidated = false;

        $('form:not([name="intro"])').on('submit', function(e) {
            if (isValidated) return;

            var $form = $(this),
                $modal = $('#modal-analysis-status');

            e.preventDefault();

            $.getJSON('/api/analysis-status', function(status) {
                if (status.ready) {
                    isValidated = true;
                    $form.submit();
                } else {
                    $('#modal-loading').hide();
                    showModal($modal);
                }
            });
        });
    })();

    // Initialize modal close buttons
    (function() {
        $('.modal').each(function() {
            var $modal = $(this),
                $btnClose = $('.modal__btn--close');

            $btnClose.on('click', function() {
                $modal.hide();
            });
        });
    })();

    /**
     * Show given modal
     * @param $modal
     */
    function showModal($modal) {
        var $popup = $modal.find('.modal__popup');

        $modal.show();

        $popup.css({
            'margin-left': $popup.width() / -2,
            'margin-top': $popup.height() / -2
        });
    }
};
