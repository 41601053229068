var ProbabilisticOutputApp = function(rdata) {
    var self = this,
        currentState = 'healthcare';

    /**
     * Render sections based on outputData
     * @access private
     * @param {jQuery} $target
     * @param {Object} data
     */
    function renderSections($target, data) {
        $.each(data, function(k, data) {
            renderSection($target, k, data);
        });
    }

    /**
     * Render a single section
     * @access private
     * @param {jQuery} $target
     * @param {String} name
     * @param {Object} data
     */
    function renderSection($target, name, data) {
        var $panel = createAccordionPanel($target, name);

        if(data.graphs) renderGraphs($panel, data.graphs);
        if(data.controls) renderControls($panel, data.controls, data.graphs);
        if(data.tables) renderTables($panel, data.tables);

        var $buttons = $panel.find('.button-toggle');

        $buttons.each(function() {
            var $e = $(this),
                $targets = $buttons.not($e);

            $e.trigger('togglebutton.sync', $targets);
        });
    }

    /**
     * Render section graphs
     * @access private
     * @param {jQuery} $target
     * @param {Object} data
     */
    function renderGraphs($target, data) {
        $.each(data, function(k, data) {
            renderGraph($target, k, data);
        });
    }

    /**
     * Render a single graph
     * @access private
     * @param {jQuery} $target
     * @param {String} name
     * @param {Object} data
     */
    function renderGraph($target, name, data) {
        var $size = $target.closest('.accordion'),
            $graph = $('<div />')
                .attr(data.attributes || {})
                .addClass('graph'),

            options = getChartOptions($size, name, data);

        $target.append($graph);

        $graph.highcharts(options);
        data.graph = $graph.highcharts();
        data.graph._originalTitle = name;
    }

    /**
     * Render section controls
     * @access private
     * @param {jQuery} $target
     * @param {Array} controls
     * @param {Object} data
     */
    function renderControls($target, controls, data) {
        $.each(controls, function(i, options) {
            var func = 'render' + options.type.toUpperCaseFirst() + 'Controls';

            if(typeof self[func] === 'function') {
                self[func]($target, options, data);
            }
        });
    }

    /**
     * Render ICER controls
     * @access private
     * @param {jQuery} $target
     * @param {Object} options
     * @param {Object} data
     */
    function renderIcerControls($target, options, data) {
        var target = data[options.target],
            $controls = $('<div>').addClass('form-inline control--threshold'),
            $title = $('<div>').addClass('form-group')
                .append($('<strong>').text(options.name))
                .append('&nbsp;'),

            name = getControlsName();

        $target.append($controls.append($title));

        if(!target) return;

        // Create input for every option
        $.each(options.options, function(label, value) {
            var $formgroup = $('<div>').addClass('form-group'),
                $label = $('<label>'),
                $radio = $('<input>').attr({
                    type: 'radio',
                    name: name,
                    value: value
                });

            $controls.append($formgroup.append($label.append($radio).append(label)));
        });

        $controls.find('input').eq(0).prop('checked', true);

        // Initialize input interaction
        $controls.find('input[name="' + name + '"]').on('change', function() {
            var threshold = parseInt($(this).val()),
                series = target.series[currentState] || target.series,
                seriesIndex = -1;

            $.each(series, function(i, serie) {
                if(serie.name !== 'WTP Threshold') return;
                seriesIndex = i;
            });

            target.graph.series[seriesIndex].setData([
                [-THRESHOLD_MULTIPLIER, threshold * -THRESHOLD_MULTIPLIER],
                [THRESHOLD_MULTIPLIER, threshold * THRESHOLD_MULTIPLIER]
            ]);
        });
    }

    // Expose function to allow access from renderControls()
    this.renderIcerControls = renderIcerControls;

    /**
     * Render section tables
     * @access private
     * @param {jQuery} $target
     * @param {Object} data
     */
    function renderTables($target, data) {
        var $tabList = $('<ul>').addClass('nav nav-tabs').attr('role', 'tablist'),
            $tabPanels = $('<div>').addClass('tab-content'),
            isFirst = true,
            shouldRenderTabs = !$.isArray(data);

        if(shouldRenderTabs) $target.append($tabList).append($tabPanels);

        $.each(data, function(name, data) {
            var id = name.toString().toLowerCase(),
                $tab = $('<li>')
                    .attr('role', 'presentation'),

                $tabLink = $('<a>')
                    .attr('href', '#' + id)
                    .attr('role', 'tab')
                    .data('toggle', 'tab')
                    .text(name),

                $panel = $('<div>').addClass('tab-pane').attr({
                    id: id,
                    role: 'tabpanel'
                });

            if(isFirst) {
                $tab.addClass('active');
                $panel.addClass('active');
                isFirst = false;
            }

            if(shouldRenderTabs) {
                $tabList.append($tab.append($tabLink));
                $tabPanels.append($panel);

                if(!data.flipped) renderTable($panel, data);
                else renderFlippedTable($panel, data);
            } else {
                if(!data.flipped) renderTable($target, data);
                else renderFlippedTable($target, data);
            }
        });
    }

    /**
     * Render a single table
     * @access private
     * @param {jQuery} $target
     * @param {Object} data
     */
    function renderTable($target, data) {
        var $table = $('<table />')
                .attr(data.attributes || {})
                .addClass('table table-striped table--data'),

            $thead = $('<thead />'),
            $tbody = $('<tbody />'),
            $row = $('<tr />'),

            buttonLabels = {
                closed: 'Show full table',
                open: 'Hide full table'
            },

            $button = $('<button>').addClass('btn btn-info btn-sm').text(buttonLabels.closed),

            totalRows = Math.ceil(data.data.length / data.columns.length),
            visibleRows = 4;

        $target.append($table.append($thead.append($row)).append($tbody));

        if(data.data.length > visibleRows) {
            $target.append($button);
        }

        // Populate $thead
        $.each(data.columns, function(i, column) {
            var columnName = $.isPlainObject(column) ? column.name : column,
                $cell = $('<th>')
                    .attr(column.attributes)
                    .addClass(getHeadColumnClass(column));

            $row.append($cell.text(columnName));
        });

        // Populate $tbody
        $.each(data.data, function(i, val) {
            if(typeof val !== 'number' && !isNaN(val)) val = Number.parseFloat(val);

            var columnIndex = i % data.columns.length,
                column = data.columns[columnIndex],
                columnName = $.isPlainObject(column) ? column.name : column,
                columnClass = getColumnTypeByText(columnName),

                row = Math.floor(i / data.columns.length),
                rowVisible = row < totalRows - visibleRows,

                $cell = $('<td>');

            if(columnIndex === 0) {
                $row = $('<tr>').toggleClass('row--preview', rowVisible);
                $tbody.append($row);
            }

            if(typeof val === 'number' && column.decimals) {
                val = val.toFixed(column.decimals);
            }

            if(columnClass) $cell.addClass(columnClass);
            $row.append($cell.text(val));
        });

        var tableVisible = false;

        $tbody.find('tr').hide().not('.row--preview').show();

        $button.on('click', function() {
            if(!tableVisible) {
                $tbody.find('tr').show();
                $button.text(buttonLabels.open);
            } else {
                $tbody.find('tr').hide().not('.row--preview').show();
                $button.text(buttonLabels.closed);
            }

            tableVisible = !tableVisible;
        });
    }

    /**
     * Render a flipped (transposed) table
     * @param $target
     * @param data
     */
    function renderFlippedTable($target, data) {
        var $table = $('<table />')
                .attr(data.attributes || {})
                .addClass('table table--data'),

            $thead = $('<thead>'),
            $tbody = $('<tbody />');

        $target.append($table.append($thead).append($tbody));

        // Populate $tbody
        $.each(data.data, function(i, val) {
            if(typeof val !== 'number' && val.length && !isNaN(val)) val = Number.parseFloat(val);

            var propertyIndex = i % data.columns.length,
                property = data.columns[propertyIndex],
                propertyName = property.name || property,

                $row = $('<tr>')
                    .addClass(getHeadColumnClass(property, true)),

                $name = $('<th>')
                    .attr(property.attributes || {})
                    .text(propertyName),

                $cell = $(i < 1 ? '<th>' : '<td>'),
                $target = $(i < 1 ? $thead : $tbody),
                $control = undefined;

            if(typeof val === 'number' && property.decimals) {
                val = val.toFixed(property.decimals);
            } else if(typeof val === 'string') {
                val = val.replace('{population-control}', function() {
                    $control = $('<span id="population-control">');
                    return '';
                });
            }

            $target.append($row.append($name).append($cell.text(val)));
            if($control) $cell.append($control);
        });
    }

    /**
     * Get column type by text
     * @access private
     * @param {String} text
     * @returns {String|undefined}
     */
    function getColumnTypeByText(text) {
        text = text.toString();

        if(text.match(/societal/i)) return 'col-societal';
        if(text.match(/healthcare/i)) return 'col-healthcare';
        return undefined;
    }

    /**
     * Get head column class
     * @param {String|Object} column
     * @returns {String}
     */
    function getHeadColumnClass(column, ignoreAttributes) {
        if(typeof column === 'string') return getColumnTypeByText(column);
        if(!column.attributes) column.attributes = {};

        var classes = [getColumnTypeByText(column.name)];
        if(!ignoreAttributes) classes.concat(column.attributes.class);

        return classes.join(' ');
    }

    /**
     * Toggle graph state
     * @access private
     * @param {String} state
     * @param {Object} data
     */
    function toggleGraphState(state, data) {
        $.each(data, function(section, data) {
            if(!data.graphs) return;

            $.each(data.graphs, function(name, data) {
                if(!data.series || !data.series[state]) return;

                // Update graph title
                data.graph.setTitle({
                    text: getGraphStateTitle(data.graph._originalTitle, state)
                });

                // Change graph series
                $.each(data.graph.series, function(i, serie) {
                    serie.setData(data.series[state][i].data);
                });
            });
        });
    }

    /**
     * Toggle average ICER state
     * @access private
     * @param {String} state
     * @param {Object} data
     */
    function toggleAverageState(state, data) {
        $('#icer-avg').text(data[state]);
    }

    /**
     * @access private
     * @param {jQuery} $size
     * @param {String} name
     * @param {Object} data
     * @returns {Object}
     */
    function getChartOptions($size, name, data) {
        var size = $size.width(),
            defaults = {
                colors: colors,
                credits: false,

                chart: {
                    height: size,
                    width: size,
                    legend: {
                        align: 'center',
                        borderWidth: 0,
                        layout: 'horizontal',
                        verticalAlign: 'bottom'
                    }
                },

                plotOptions: {
                    line: {
                        marker: {
                            enabled: false,
                            symbol: 'circle'
                        }
                    },
                    scatter: {marker: {radius: 5}}
                },

                tooltip: {
                    valueDecimals: 3
                },

                xAxis: {
                    tickmarkPlacement: 'on'
                }
            },

            options = $.extend(true, {}, defaults, $.cloneObject(data));

        var stateSeries = options.series[currentState],
            title = stateSeries ? getGraphStateTitle(name, currentState) : name;

        options.title = {text: title};
        options.series = stateSeries || options.series;

        return options;
    }

    function getAverageNode(average) {
        return $('<p>')
            .attr('id', 'icer-avg')
            .text('Average ICER: ' + average[currentState]);
    }

    /**
     * Get population control
     * @returns {jQuery}
     */
    function getPopulationControl(outcome, population) {
        var $group = $('<div>').addClass('form-group control control--population'),
            $label = $('<label>').append(
                $('<span>').text('Population:')
            ),
            $input = $('<input>')
                .attr({type: 'number'})
                .addClass('form-control form-control-inline input-sm')
                .val(population),
            $reset = $('<button>')
                .addClass('btn-default btn-sm btn-success')
                .text('Reset'),
            timeout;

        $group.append($label.append($input).append($reset));

        $reset.on('click', function() {
            $input.val(population).trigger('change');
        });

        $input.on('keyup change', function() {
            var data = $.map(outcome, function(value) {
                return value * $input.val();
            });

            if(timeout) clearTimeout(timeout);

            timeout = setTimeout(function() {
                $('#population-impact tr td:nth-child(2)').map(function(i, e) {
                    var $e = $(e);
                    $e.text(Number.parseFloat(data[i]).toFixed(2));
                });
            }, 1000 / 60);
        });

        return $group;
    }

    /****************************************************************
     * Initialisation
     ****************************************************************/

    var $target = $('#output'),
        $accordion = createAccordion($target);

    // Populate accordion
    renderSections($accordion, rdata);
    toggleTableState(currentState);

    // Create accordion
    $accordion.accordion({
        heightStyle: 'content'
    });

    var data = rdata['Cost-effectiveness and population impact on resource use and HRQoL effects'].data;

    // Add togglebutton
    $.toggleButton({
        label: {
            off: 'Healthcare costs',
            on: 'Societal costs'
        }
    }).on('togglebutton.on', function() {
        if(currentState === 'societal') return;

        currentState = 'societal';
        toggleGraphState(currentState, rdata);
        toggleTableState(currentState);
        toggleAverageState(currentState, data.icer);
    }).on('togglebutton.off', function() {
        if(currentState === 'healthcare') return;

        currentState = 'healthcare';
        toggleGraphState(currentState, rdata);
        toggleTableState(currentState);
        toggleAverageState(currentState, data.icer);
    }).insertBefore($accordion);

    //$('#cost-effective-plane').closest('.accordion__panel')
    //    .append(getAverageNode(data.icer));

    // Initialize tabs
    $('.nav-tabs a').click(function(e) {
        e.preventDefault();
        $(this).tab('show');
    });

    /****************************************************************
     * Text injection
     ****************************************************************/

    $('<p>').text('These outcomes show the total impact of the intervention on healthcare costs and health-related Quality of Life for the total target population. The outcomes represent the weighted average of the outcomes for each age-gender combination, weighted by the distribution of age-gender in the specified target country and age range. In this section a cost-effectiveness plane is shown. The y-axis represents the incremental cost per person while the x-axis shows the incremental health effects (QALY). The diagonal line in this graph reflects the willingness-to-pay (WTP) per additional QALY threshold. Each blue dot represents the model output for one iteration (i.e. one set of input parameters drawn from the probability distributions specified in the previous step).')
        .add($('<p>').text('In addition, the table shows the expected population-level impact on incremental cost and health-related Quality of Life, as well as the incremental cost-effectiveness ratio (ICER). The expected impact on cost and health-related Quality of Life is the average of all iterations. When an intervention is dominant (both more effective and less costly) or dominated (less effective and more costly) in all iterations, this outcome is shown instead of the ICER.'))
        .insertBefore($('#cost-effective-plane'));

    $('<p>').text('The figure below depicts a cost-effectiveness acceptability curve (CEAC). This figure shows the probability of the intervention being cost-effective at a range of WTP levels. This probability at a certain WTP level is the percentage of iterations for which the ICER of the intervention is below that WTP value. As the willingness-to-pay increases, the proportion of iterations falling below this threshold, and thus the probability of the intervention being cost-effective will increase. The probability at WTP = 0 denotes the probability that the intervention is dominant.').insertBefore($('#cost-effective-curve'));


};