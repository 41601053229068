(function($) {
    /**
     * Check whether an object is an Array
     * @param obj
     * @returns {boolean}
     */
    function isArray(obj) {
        return $.isArray(obj);
    }

    /**
     * Check whether an object is an Object
     * @param obj
     * @returns {boolean}
     */
    function isObject(obj) {
        return typeof obj === 'object' && obj !== null;
    }

    /**
     * Check whether an object is cloneable
     * @param obj
     * @returns {boolean}
     */
    function isCloneable(obj) {
        return isArray(obj) || isObject(obj);
    }

    /**
     * Clone object
     * @param obj
     * @returns {Object|Array}
     */
    $.cloneObject = function(obj) {
        if(isArray(obj)) {
            return $.map(obj, function(val) {
                if(!isCloneable(val)) return val;
                return $.cloneObject(val);
            });
        }

        if(isObject(obj)) {
            return $.extend(true, {}, obj);
        }

        return obj;
    };
})(jQuery);