/**
 * Initialize Sensitivity output
 * @param {Object} data
 * @param {Array}  labels
 */
var SensitivityApp = function(rdata, labels) {
    var statistics = Object.keys(rdata.sensitivity),

        currentState = 'healthcare',
        currentStatistic = statistics[0],
        currentThreshold = 15000,
        graphICER, graphTornadoCosts, graphTornadoEffects;

    // Sort data series, because Highcharts won't
    $.each(rdata.sensitivity, function(k, data) {
        if($.isPlainObject(data.series)) {
            $.each(data.series, function(k, series) {
                sortSeries(series);
            });
        } else sortSeries(data.series);
    });

    /**
     * Renders box to select state
     * @param $target
     * @param states
     */
    function renderStateSelector($target, states) {
        var $group = $('<div>').addClass('form-group'),
            $select = $('<select>');

        $target.append($group.append($select));

        $.each(states, function(i, state) {
            var $option = $('<option>').attr('value', state).text(labels[state].label);
            $select.append($option);
        });

        $select.on('change', function() {
            currentStatistic = $select.val();
            updateGraphState(currentState, rdata.sensitivity);
        });
    }

    /**
     * Renders ICER graph
     * @param $target
     * @param name
     * @param data
     */
    function renderGraph($target, name, data) {
        var $graph = $('<div>').addClass('graph'),
            $size = $target.parent(),
            size = $size.width(),

            title = getGraphStateTitle(name, currentState),
            series = $.cloneObject(data.series[currentState]);

        $target.append($graph);

        var thresholdIntersectionData = getThresholdIntersectionData(currentThreshold);
        if(thresholdIntersectionData) series.push(thresholdIntersectionData);

        $graph.highcharts({
            colors: colors,
            credits: false,

            chart: {
                height: Math.max(size / 2, 320),
                width: size,
                legend: {
                    align: 'center',
                    borderWidth: 0,
                    layout: 'horizontal',
                    verticalAlign: 'bottom'
                }
            },

            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: false
                    },
                    marker: { symbol: 'circle' }
                },
                scatter: {
                    marker: { radius: 5 },
                    tooltip: {
                        pointFormat: 'x: <strong>{point.x:,.3f}</strong><br />y: <strong>{point.y:,.3f}</strong>'
                    }
                }
            },

            title: { text: title },
            xAxis: $.extend({ tickmarkPlacement: 'on' }, data.xAxis),
            yAxis: data.yAxis,
            series: series
        });

        graphICER = $graph.highcharts();
        graphICER._originalTitle = name;
    }

    /**
     * Render ICER controls
     * @param {jQuery} $target
     * @param {Object} options
     * @param {Object} target
     */
    function renderIcerControls($target, options, target) {
        var $controls = $('<div>').addClass('form-inline control--threshold'),
            $title = $('<div>').addClass('form-group')
                .append($('<strong>').text(options.name))
                .append('&nbsp;'),

            name = getControlsName();

        $target.append($controls.append($title));

        if(!target) return;

        // Create input for every option
        $.each(options.options, function(label, value) {
            var $formgroup = $('<div>').addClass('form-group'),
                $label = $('<label>'),
                $radio = $('<input>').attr({
                    type: 'radio',
                    name: name,
                    value: value
                });

            $controls.append($formgroup.append($label.append($radio).append(label)));
        });

        // Set default WTP Threshold
        $controls.find('input').eq(0).prop('checked', true);

        // Initialize input interaction
        $controls.find('input[name="' + name + '"]').on('change', function() {
            var series = target.series[currentState] || target.series,
                seriesIndex = -1;

            currentThreshold = parseInt($(this).val());

            $.each(series, function(i, serie) {
                if(serie.name !== 'WTP Threshold') return;
                seriesIndex = i;
            });

            // Change WTP Theshold
            target.series[seriesIndex].setData([
                [-THRESHOLD_MULTIPLIER, currentThreshold * -THRESHOLD_MULTIPLIER],
                [THRESHOLD_MULTIPLIER, currentThreshold * THRESHOLD_MULTIPLIER]
            ]);

            updateIntersectionData();
        });
    }

    /**
     * Renders tornado graph
     * @param $target
     * @param name
     * @param data
     */
    function renderTornadoGraph($target, name, data) {
        var $graph = $('<div>').addClass('graph'),
            $size = $target.parent(),
            size = $size.width(),

            title = getGraphStateTitle(name, currentState),

            categories = getTornadoCategories(data[currentState]),
            series = getTornadoSeries(data);

        $target.append($graph);

        $graph.highcharts({
            colors: colors,
            credits: false,
            title: title,

            chart: {
                type: 'bar',
                height: size / 2,
                width: size,
                legend: {
                    align: 'center',
                    borderWidth: 0,
                    layout: 'horizontal',
                    verticalAlign: 'bottom'
                }
            },

            xAxis: [{
                categories: categories,
                reversed: true
            }, {
                opposite: true,
                reversed: true
            }],

            plotOptions: {
                series: {
                    stacking: 'normal'
                },

                bar: {
                    tooltip: {
                        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <strong>{point.y:,.3f}</strong>'
                    }
                }
            },

            series: series
        });

        return $graph.highcharts();
    }

    /**
     * Get series fit for tornado graph
     * @param data
     * @returns {Array.<Object>}
     */
    function getTornadoSeries(data) {
        var series = [{
            name: 'Minimum input value',
            data: []
        }, {
            name: 'Maximum input value',
            data: []
        }];

        $.each(data[currentState], function(i, statistic) {
            series[0].data.push(statistic.min);
            series[1].data.push(statistic.max);
        });

        return series;
    }

    /**
     * Get list of categories for tornado graph
     * @param data
     * @returns {Array.<String>}
     */
    function getTornadoCategories(data) {
        return Object.keys(data).map(function(category) {
            return labels[category].label;
        });
    }

    /**
     * Update graph state
     * @param {String} state
     * @param {Object} data
     */
    function updateGraphState(state, data) {
        data = data[currentStatistic];

        graphICER.xAxis[0].setExtremes(data.xAxis.min, data.xAxis.max);
        graphICER.yAxis[0].setExtremes(data.yAxis.min, data.yAxis.max);

        graphICER.setTitle({
            text: getGraphStateTitle(graphICER._originalTitle, state)
        });

        $.each(data.series[state], function(i, ndata) {
            graphICER.series[i].update(ndata);
        });

        updateIntersectionData();
        $('.control--threshold input:checked').triggerHandler('change');
    }

    /**
     * Update tornado state
     * @param {Object} data
     */
    function updateTornadoState(graph, data) {
        var categories = getTornadoCategories(data[currentState]),
            series = getTornadoSeries(data);

        $.each(graph.series, function(i, serie) {
            var serieData = series[i].data;
            serie.setData(serieData);
        });

        graph.xAxis[0].setCategories(categories);
    }

    function updateIntersectionData() {
        var seriesIndex = -1,
            intersection = getThresholdIntersectionData(currentThreshold);

        $.each(graphICER.series, function(i, serie) {
            if(serie.name !== 'Threshold intersection') return;
            seriesIndex = i;
        });

        graphICER.series[seriesIndex].update(intersection);
    }

    function getThresholdIntersectionData(threshold) {
        threshold = threshold.toString();

        var point = rdata.sensitivity[currentStatistic].thresholdIntersection[currentState][threshold];
        point.x = Number.parseFloat(point.x) || 0;
        point.y = Number.parseFloat(point.y) || 0;
        point.visible = point.visible === true;

        return {
            name: 'Threshold intersection',
            type: 'scatter',
            data: [point],
            showInLegend: false,
            visible: point.visible
        };
    }

    /****************************************************************
     * Initialisation
     ****************************************************************/

    var $target = $('#output'),
        $accordion = createAccordion($target);

    // Add togglebutton
    $.toggleButton({
        label: {
            off: 'Healthcare costs',
            on: 'Societal costs'
        }
    }).on('togglebutton.on', function() {
        if(currentState === 'societal') return;

        currentState = 'societal';
        updateGraphState(currentState, rdata.sensitivity);
        updateTornadoState(graphTornadoCosts, rdata.tornadoCosts);
    }).on('togglebutton.off', function() {
        if(currentState === 'healthcare') return;

        currentState = 'healthcare';
        updateGraphState(currentState, rdata.sensitivity);
        updateTornadoState(graphTornadoCosts, rdata.tornadoCosts);
    }).insertBefore($accordion);

    // Create ICER graph
    (function() {
        var graphName = rdata.sensitivity[currentStatistic].name,
            $graphPanel = createAccordionPanel($accordion, graphName);

        $.each(rdata.sensitivity, function(statistic, sData) {
            $.each(sData.series, function(state, series) {
                var parameterRange = series.filter(function(a) {
                    return a.name === 'Parameter range';
                }).pop();

                var extremes = parameterRange.data.map(function(data) {
                    return {
                        type: 'scatter',
                        name: data.name,
                        color: data.color,
                        marker: parameterRange.marker,
                        data: [data]
                    };
                });

                sData.series[state] = series.concat(extremes);
            });
        });

        renderStateSelector($graphPanel, statistics);
        renderGraph($graphPanel, graphName, rdata.sensitivity[currentStatistic]);

        if(graphICER) {
            var options = {
                name: 'WTP Threshold:',
                options: {}
            };

            options.options[rdata.currency + '15K/QALY'] = 15000;
            options.options[rdata.currency + '20K/QALY'] = 20000;
            options.options[rdata.currency + '30K/QALY'] = 30000;
            options.options[rdata.currency + '50K/QALY'] = 50000;
            options.options[rdata.currency + '80K/QALY'] = 80000;

            renderIcerControls($graphPanel, options, graphICER);
        }
    })();

    // Create tornado graph
    (function() {
        var graphName = 'Parameter impact on incremental costs',
            $graphPanel = createAccordionPanel($accordion, graphName);

        graphTornadoCosts = renderTornadoGraph($graphPanel, graphName, rdata.tornadoCosts);
    })();

    // Create tornado graph
    (function() {
        var graphName = 'Parameter impact on incremental effects',
            $graphPanel = createAccordionPanel($accordion, graphName);

        graphTornadoEffects = renderTornadoGraph($graphPanel, graphName, rdata.tornadoEffects);
    })();

    // Create accordion
    $accordion.accordion({
        heightStyle: 'content'
    });


    /****************************************************************
     * Text injection
     ****************************************************************/

    $('.accordion__panel').eq(0)
        .prepend($('<p>').text('The cost-effectiveness plane which is displayed here allows you to choose, one at a time, the parameter that you would like to analyse. It shows how the range that you previously specified for this parameter compares to the base case outcome in incremental terms. By selecting a WTP per QALY threshold you can see whether certain input values lead to incremental cost and effect combinations above or below this threshold.'));

    $(graphTornadoCosts.container).closest('.accordion__panel')
        .prepend($('<p>').html('The Tornado diagram displayed here summarises the impact of a change in each parameter on <strong>incremental cost</strong> of your intervention relative to the base case.'))
        .append($('<p>').html('The parameter leading to the highest change in <strong>incremental costs</strong> is displayed at the top of the diagram, followed by the parameter leading to the second highest change in incremental cost of your intervention, and so on and so forth. Ordering parameters from the one having most impact to the one with least impact on incremental costs leads to the characteristic tornado-shape of the diagram.'));

    $(graphTornadoEffects.container).closest('.accordion__panel')
        .prepend($('<p>').html('The Tornado diagram displayed here summarises the impact of a change in each parameter on <strong>incremental effects</strong> of your intervention relative to the base case.'))
        .append($('<p>').html('The parameter leading to the highest change in <strong>incremental effects</strong> is displayed at the top of the diagram, followed by the parameter leading to the second highest change in incremental effects of your intervention, and so on and so forth. Ordering parameters from the one having most impact to the one with least impact on incremental effects leads to the characteristic tornado-shape of the diagram.'));
};
