!function ($) {
	"use strict";

    /**
     * Last request for caching
     * @type {{url: string, data_plain: string, resp: {}, loading: boolean}}
     */
	var last_request = {url:'', data_plain: '', resp: {}, loading: false};

    /**
     * Add the message as a tooltip to the element
     * @param {Object} elem
     * @param {String} message
     */
	var addTooltip = function(elem, message) {
		if(message == undefined) {
			message = elem.data('original-title');
			elem.attr('data-original-title', null);
			elem.data('original-title', null);
		}
		var trigger = 'hover focus';

		if(elem.parents('.tooltip-container').length) {
			elem = elem.parents('.tooltip-container');
		}

		elem.tooltip({html:true, trigger: trigger, container: 'body', title: message, placement: 'top'});
	};

    /**
     * Remove the tooltip of to the element
     * @param {Object} elem
     */
	var removeTooltip = function(elem) {
		if(elem.parents('.tooltip-container').length) {
			elem = elem.parents('.tooltip-container');
		}

		elem.tooltip('destroy');
		elem.data('tooltip', false);
		elem.attr('data-original-title', null);
		elem.data('original-title', null);
	};

    /**
     * Extract the error message of the element by the name from the response
     * @param {String} name
     * @param {Object} resp
     */
	var handleResponse = function(name, resp)
	{
		var elem = $('*[name="' + name + '"]');
		var container = elem.closest('.form-group');
		removeTooltip(elem);

        var message = resp[name];
        // When the element is in a fieldset, search for the nested message and element
        if (typeof resp[name] === 'undefined' && !$.isEmptyObject(resp) && name.indexOf('[') !== -1) {
            var elementName = name.split('[')[1].replace(']', '');
            var fieldsetName = name.split('[')[0];

            if (fieldsetName in resp) {
                var elementMessage = resp[fieldsetName][elementName];
                if (typeof elementMessage !== 'undefined') {
                    // Get the first (and only) property containing the message
                    message = elementMessage[Object.keys(elementMessage)[0]];
                }
            }
        }

		if(message) {
			if(!container.is('.error')) {
				container.addClass('error');
			}
			addTooltip(elem, message);
		} else {
			if(container.is('.error')) {
				container.removeClass('error');
			}
		}
	};

    /**
     * Validate the element with given name
     * @param {String} name
     */
	var doValidation = function(name) {
		var url = '';

		for(var i = 0; i < $.url.segment(); i++) {
			url += '/' + $.url.segment(i);
		}
		url += '/validate';
		var data = {};
		var data_plain = '';
		
		// only get the element from the current form
		$('*[name="' + name + '"]').parents('form').find('input[type!=file][type!=button][type!=submit], textarea, select').each(function() {
			// only use selected radio and checkbox values
			if($(this).is(':radio, :checkbox') && !$(this).is(':checked')) return true;
			
			var element_name = $(this).attr('name');
			if(element_name == undefined) return true;

	    	data[element_name] = $(this).val();
	    	data_plain += element_name + '::' + data[element_name] + '_'; 
		});
		
		if(last_request.url == url && last_request.data_plain == data_plain) {
			if(!last_request.loading) {
				handleResponse(name, last_request.resp);
			}
			return false;
		}
		
		last_request.url = url;
		last_request.data_plain = data_plain;
		last_request.loading = true;
		
		$.post(url,data,function(resp) {
			if(last_request.url == url && last_request.data_plain == data_plain) {
				last_request.resp = resp;
				last_request.loading = false;
			}
			handleResponse(name, resp);
		},'json');
	};

	$.extend({
        /**
         * Add validation listener to all inputs, textareas and select of the forms
         */
		addValidation: function() {
			$('*[data-toggle=tooltip]').each(function() {
				addTooltip($(this));
			});
			
			$('form').on('blur change', 'input[type!=hidden][type!=file][type!=button][type!=submit], textarea, select', function() {
				doValidation($(this).attr('name'));
			});
		}
	});

    /**
     * Initialize validation
     */
    if($.addValidation !== undefined) {
        $.addValidation();
    }
}(window.jQuery);
