/**
 * @public
 * @returns {string}
 */
String.prototype.toUpperCaseFirst = function() {
    var first = this.substr(0, 1).toUpperCase(),
        remaining = this.substr(1);

    return first + remaining;
};
